
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(["data"]),
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.name = "";
          this.$emit("close");
        }
      },
    },
  },
  data: () => ({
    name: "",
    loading: false,
  }),
  methods: {
    ...mapActions(["refreshAssociations", "addAssociation"]),
    async clickRoutine() {
      this.loading = true;
      await this.addAssociation(this.name);
      this.loading = false;
      this.showDialog = false;
    },
  },
});
