
import Vue from "vue";
import Association from "@/store/interfaces/Association";
import AssociationDialogAdd from "@/components/AssociationDialogAdd.vue";
import { mapActions } from "vuex";

export default Vue.extend({
  components: {
    AssociationDialogAdd,
  },
  props: {
    associations: {
      type: Array,
      required: true,
    },
  },
  computed: {
    confirmationDialog: {
      get(): boolean {
        return this.showConfirmationDialog;
      },
      set(value: boolean) {
        if (!value) {
          this.associationToDelete = 0;
          this.showConfirmationDialog = false;
        }
      },
    },
  },
  data: () => ({
    showAddDialog: false,
    showConfirmationDialog: false,
    associationToDelete: 0,
    deleteLoading: false,
    searchFilter: "",
    headers: [
      {
        text: "Association",
        align: "start",
        value: "name",
        filterable: true,
      },
      {
        text: "Supprimer",
        align: "end",
        value: "delete",
        filterable: false,
      },
    ],
  }),
  methods: {
    ...mapActions(["deleteAssociation"]),
    deleteClick(association: Association) {
      this.associationToDelete = association.id;
      this.showConfirmationDialog = true;
    },
    async confirmDelete() {
      this.deleteLoading = true;
      await this.deleteAssociation(this.associationToDelete);
      this.deleteLoading = false;
      this.confirmationDialog = false;
    },
  },
});
