
import Association from "@/store/interfaces/Association";
import Vue from "vue";

export default Vue.extend({
  props: {
    otherAssos: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Association",
        align: "start",
        value: "name",
      },
      {
        text: "Numéro de coupon",
        align: "end",
        value: "couponId",
      },
      {
        text: "Ajout à la base",
        align: "end",
        value: "addToDb",
      },
    ],
  }),
  methods: {
    disableCreate(name: string): boolean {
      const matchIndex = (
        this.$store.state.data.associations as Array<Association>
      ).findIndex((value) => {
        return value.name.toLowerCase() == name.toLowerCase();
      });
      return matchIndex !== -1;
    },
    async addToDatabase(item: Association) {
      await this.$store.dispatch("addAssociation", item.name);
    },
  },
});
