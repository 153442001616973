
import { CouponPreview } from "@/store/interfaces/Coupon";
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import AssociationTable from "@/components/AssociationTable.vue";
import AssociationOtherTable from "@/components/AssociationOtherTable.vue";
import Association from "@/store/interfaces/Association";

export default Vue.extend({
  components: {
    AssociationTable,
    AssociationOtherTable,
  },
  computed: {
    ...mapState(["data"]),
    otherAssociations() {
      const otherAssociations = [] as Array<{ name: string; couponId: string }>;
      if (this.data.coupons) {
        for (const coupon of this.data.coupons as Array<CouponPreview>) {
          if (coupon.associationOther) {
            otherAssociations.push({
              name: coupon.associationOther,
              couponId: `${new Date(coupon.createdAt).getFullYear()}-${
                coupon.index
              }`,
            });
          }
        }
      }
      return otherAssociations;
    },
  },
  data: () => ({
    open: [0],
  }),
  methods: {
    ...mapActions(["refreshAssociations"]),
  },
  async beforeMount() {
    this.refreshAssociations();
    if (
      this.$store.state.data.coupons == null ||
      this.$store.state.data.coupons.length == 0
    ) {
      await this.$store.dispatch("refreshCoupons");
    }
  },
});
